import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { REASON_LABEL, SHIFT_INFORMATION_TITLE, TOTAL_PAY_LABEL } from 'locales/shiftClaimDetails/es';
import { SPECIALIZATION_TABLE_TITLE } from 'locales/shiftClaimList/es';
import { ShiftClaim } from 'types';
import { formatDate, formatSchedule } from 'utils';
import BoldTitleAndValue from './common/BoldTitleAndValue';
import { Box } from '@mui/material';

export default function ShiftInformationCard({ claim, rightHeader }: { claim: ShiftClaim, rightHeader?: JSX.Element }) {
    const reasonDisplay = claim.slotReason ? claim.slotReason.displayText + (claim.slotReason.comment ? " - " + claim.slotReason.comment : "") : "";
    return (
        <Card sx={{m: 1}}>
            <CardContent>
                <div className="flex flex-row justify-between">
                    <div>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {SHIFT_INFORMATION_TITLE}
                        </Typography>
                        <Typography variant="h5" component="div">
                            {formatDate(claim.shift.startTime)}
                        </Typography>
                        <Typography>
                            {formatSchedule(claim.shift.startTime, claim.shift.finishTime)}
                        </Typography>
                        <BoldTitleAndValue title={TOTAL_PAY_LABEL} value={claim.shift.totalPay + "€"} classname='my-[24px]'/>
                        <BoldTitleAndValue title={SPECIALIZATION_TABLE_TITLE} value={claim.shift.specialization.translations.es}/>
                        { claim.slotReason &&
                            <BoldTitleAndValue title={REASON_LABEL} value={reasonDisplay}/>
                        }
                    </div>
                    <div>
                        {rightHeader}
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}
